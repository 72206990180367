<template lang="pug">
  tr.table__row.table__row--body
    td.table__cell {{ product.name }}
    td.table__cell {{ product.weight  }} {{ 'units.kg' | translate }}
    td.table__cell {{ product.cost }}
    td.table__cell
      //- .table__cell-wrapper
        .table__cell-item
          qr-code-dropdown(
            position-right
            :name="product.name")

</template>

<script>
import QrCodeDropdown from '@/components/common/qr-code-dropdown/QrCodeDropdown'

export default {
  name: 'FinalProductTableRow',

  components: {
    QrCodeDropdown
  },

  props: {
    product: {
      type: Object,
      required: true
    }
  }
}
</script>

<style lang="scss" scoped>
</style>
