<template lang="pug">
  .qr-code-dropdown.text-center
    v-dropdown(
      ref="dropdown"
      width-auto
      :position-right="positionRight")
      v-btn(
        icon
        small)
        v-icon(
          font-size="1.3"
          icon="qrcode")
      .qr-code-dropdown__content(
        ref="dropdownContent"
        slot="content")
        v-card(
          elevated)
          v-card-content
            v-image-skeleton(
              v-if="loading && url && !isError"
              :height="205")
            v-icon(
              v-if="!url || isError"
              font-size="14"
              icon="window-close"
              color="#c1c1c1")
            img.qr-code-dropdown__img(
              :src="url"
              :class="{ 'qr-code-dropdown__img--hidden': loading || isError }"
              @load="onLoad($event)"
              @error="onError")
          v-card-actions
            v-btn(
              :disabled="isError || !url"
              @click="onDownload"
              block)
              v-icon(
                position-left
                font-size="1.2"
                icon="download")
              span {{ 'actions.download' | translate }}

</template>

<script>

export default {
  name: 'QrCodeDropdown',

  data: () => ({
    loading: true,
    isError: false
  }),

  props: {
    name: {
      type: String,
      default: ''
    },
    url: {
      type: String
    },
    positionRight: {
      type: Boolean,
      default: false
    }
  },

  methods: {
    onLoad (e) {
      if (e.target.src) {
        this.loading = false
      }
    },

    onError () {
      this.isError = true
    },

    wrapText (ctx, text, x, y, maxWidth, lineHeight) {
      const words = text.split(' ')
      let line = ''

      for (let n = 0; n < words.length; n++) {
        const testLine = line + words[n] + ' '
        const metrics = ctx.measureText(testLine)
        const testWidth = metrics.width
        if (testWidth > maxWidth && n > 0) {
          ctx.fillText(line, x, y)
          line = words[n] + ' '
          y += lineHeight
        } else {
          line = testLine
        }
      }
      ctx.fillText(line, x, y)
    },

    onDownload () {
      const url = this.url
      const canvas = document.createElement('canvas')
      const ctx = canvas.getContext('2d')
      const filename = url.split('/').reverse()[0]

      canvas.width = 350
      canvas.height = 430

      const maxWidth = 300
      const lineHeight = 25
      const x = 25
      const y = 360
      const text = this.name

      ctx.fillStyle = '#fff'
      ctx.fillRect(0, 0, 350, 430)

      const img = new Image()
      img.crossOrigin = 'anonymous'

      img.onload = () => {
        ctx.drawImage(img, 25, 25, 300, 300)
        ctx.font = '20px Museo Sans'
        ctx.fillStyle = '#000'
        this.wrapText(ctx, text, x, y, maxWidth, lineHeight)

        const img2 = canvas.toDataURL('image/png')
        const link = document.createElement('a')
        link.download = filename
        link.href = img2
        document.body.appendChild(link)
        link.click()
        document.body.removeChild(link)
      }
      img.src = url
    }
  }
}
</script>

<style lang="scss">
  .qr-code-dropdown {

    &__content {
      width: 25rem;
    }

    &__img {
      width: 100%;

      &--hidden {
        display: none;
      }
    }
  }
</style>
