<template lang="pug">
  .table-layout
    table.table.table--cell-overflow-hidden.table--cell-overflow-visible
      thead.table__header
        tr.table__row.table__row--header
          th.table__cell {{ 'base.name' | translate }}
          th.table__cell {{ 'base.remainder' | translate }} [{{ 'units.kg' | translate }}]
          th.table__cell {{ 'base.cost' | translate }}
      v-table-body(
        :cols="3"
        :loading="loading"
        :empty="!doneProducts.length")
        final-product-table-row(
          v-for="product in doneProducts"
          :key="product.id"
          :product="product")

      tfoot.table__footer(
        v-if="!loading && showPagination")
        tr.table__row.table__row--footer
          td.table__cell(colspan="5")
            v-paginate(
              v-model="page"
              :page-count="pageCount"
              :click-handler="pageChangeHandler")

</template>

<script>
import { mapActions, mapGetters } from 'vuex'

import paginationMixin from '@/mixins/pagination.mixin'

import FinalProductTableRow from './FinalProductTableRow'

export default {
  name: 'FinalProductTable',

  mixins: [paginationMixin],

  components: {
    FinalProductTableRow
  },

  computed: {
    ...mapGetters([
      'doneProducts'
    ])
  },

  mounted () {
    this.setupPagination(this.fetchDoneProducts)
  },

  methods: {
    ...mapActions([
      'fetchDoneProducts'
    ])
  }
}
</script>

<style lang="scss" scoped>
</style>
